<script lang="ts" setup>
// Komponent odpowiedzialny za nawigacjei command bar w Przedmiotach roboczych

import { useNav } from '~coreComposables/use-nav';

const { getActivePageTitle } = useNav();
</script>

<template>
  <div class="subnav">
    <div class="title">
      <slot name="title">
        <!-- jeśli strona ma kilka zakładek -->

        <!-- wersja tymczasowa póki nie mamy opracowanych kolejnych zakładek -->

        <NuxtLink
          v-if="getActivePageTitle"
          :to="{ name: $routeNames.bailiff.auctions.index }"
        >
          <CdsText
            color="secondary-on-surface"
            variant="normal-h2"
            >{{ getActivePageTitle }}</CdsText
          >
        </NuxtLink>

        <!--
        W docelowej wersji aplikacji odkomentować - pozwala na zmianę otwartej zakładki na inną w danym module np Z E-licytacji na Licytacje w trybie tradycyjnym
        <CdsMenu v-if="activeNavData?.children?.length">
          <template #activator="{ props }">
            <CdsBtn
              v-bind="props"
              append-icon="chevron_down"
              variant="text"
            >
              <CdsText
                color="secondary-on-surface"
                variant="normal-h2"
                >{{ getActivePageTitle }}</CdsText
              >
            </CdsBtn>
          </template>

          <CdsMenuList size="regular">
            <CdsMenuListItem
              v-for="(item, index) in activeNavData?.children"
              :key="index"
              :to="item.to"
              :disabled="item.isDisabled"
            >
              {{ item.text }}
            </CdsMenuListItem>
          </CdsMenuList>
        </CdsMenu> -->

        <!-- jeśli to jest jedyna zakładka strony -->
        <!-- <CdsText
          v-else
          color="secondary-on-surface"
          variant="normal-h2"
          >{{ getActivePageTitle }}</CdsText
        > -->
      </slot>
    </div>

    <slot />
  </div>
</template>

<style lang="scss" scoped>
@import '~coreStyles/components/nav/subnav';
</style>
