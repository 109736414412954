<script lang="ts" setup>
// Opcje dla zalogowanego komornika w nagłówku

import { useUserAuthStore } from '~coreStores/user-auth.store';
import { useContextStore } from '~coreStores/context.store';

import { getInitials } from '~coreUtils/name-initials';
import { computed, defineAsyncComponent } from 'vue';

const showManageBidderAccountModal = ref(false);
const showBailiffAccountModal = ref(false);
const userAuthStore = useUserAuthStore();
const { getBailiffOfficeUserData } = storeToRefs(userAuthStore);

const contextStore = useContextStore();
const { getActiveContext } = storeToRefs(contextStore);

const route = useRoute();
const { $routeNames } = useNuxtApp();

const showMenu = computed(() => route.name === $routeNames.index || getBailiffOfficeUserData.value);

const componentManageBidderAccountModal = computed(() => {
  // @TODO przenieść z LK do CORE - błąd TS przy import
  if (showManageBidderAccountModal.value)
    return defineAsyncComponent(() => import('@Components/edit-bidder-account/ManageBidderAccountModal.vue'));
});

const componentManageBailiffAccountModal = computed(() => {
  // @TODO przenieść z LK do CORE - błąd TS przy import
  if (showBailiffAccountModal.value)
    return defineAsyncComponent(() => import('@Components/bailiff-account/BailiffAccountModal.vue'));
});

function toggleManageBidderAccountModal() {
  showManageBidderAccountModal.value = !showManageBidderAccountModal.value;
}

function toggleBailiffAccountModal() {
  showBailiffAccountModal.value = !showBailiffAccountModal.value;
}
</script>

<template>
  <CdsMenu v-if="showMenu">
    <template #activator="{ props }">
      <CdsBtn
        size="small"
        variant="text"
        v-bind="props"
      >
        <CdsTooltip
          v-if="getActiveContext"
          variant="solid"
          type="default"
          size="regular"
          location="bottom left"
        >
          <template #activator="{ props }">
            <CdsAvatar
              v-if="getBailiffOfficeUserData"
              v-bind="props"
              size="small"
            >
              {{ getInitials(getBailiffOfficeUserData.name) }}
            </CdsAvatar>
            <CdsBtn
              v-else
              v-bind="props"
              icon="hamburger_menu"
              variant="text"
            />
          </template>

          {{ getActiveContext.office_name }}
        </CdsTooltip>
      </CdsBtn>
    </template>

    <CdsMenuList
      variant="plain"
      size="regular"
    >
      <!-- Ten element jest tylko na cele testowe, zostanie on wyeliminowany jak dostaniemy finalny nagłówek od UX -->
      <CdsMenuListItem
        v-if="$route.name === $routeNames.index"
        prepend-icon="hammer"
        :to="{ name: $routeNames.bailiff.login }"
        :exact="true"
      >
        {{ $t('header.imBailiff') }}
      </CdsMenuListItem>

      <template v-if="getBailiffOfficeUserData">
        <CdsMenuListItem
          prepend-icon="user"
          @click="toggleBailiffAccountModal"
          :exact="true"
        >
          {{ $t('header.myAccount') }}
        </CdsMenuListItem>

        <CdsDivider />

        <CdsMenuListItem
          prepend-icon="user_interview"
          @click="toggleManageBidderAccountModal"
          :exact="true"
        >
          {{ $t('header.bidderAccount') }}
        </CdsMenuListItem>

        <CdsDivider />

        <CdsMenuListItem
          prepend-icon="user_flow"
          :to="{
            name: $routeNames.bailiff.context
          }"
          :exact="true"
        >
          {{ $t('header.changeContext') }}
        </CdsMenuListItem>

        <CdsMenuListItem
          prepend-icon="sign_out"
          @click="userAuthStore.logoutUser()"
        >
          {{ $t('header.logout') }}
        </CdsMenuListItem>
      </template>
    </CdsMenuList>
  </CdsMenu>

  <component
    :is="componentManageBidderAccountModal"
    v-on:close="toggleManageBidderAccountModal"
  />

  <component
    :is="componentManageBailiffAccountModal"
    @close="toggleBailiffAccountModal"
  />
</template>
