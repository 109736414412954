<script lang="ts" setup>
// Linki dla zalogowanego komornika w nagłówku np regulamin, instrukcja, polityka prywatności, faq

import { useHeaderLinks } from '~coreComposables/header-links.composable';

const { options } = useHeaderLinks();
</script>

<template>
  <CdsMenu location="bottom right">
    <template #activator="{ props }">
      <CdsBtn
        icon="help"
        size="small"
        variant="text"
        v-bind="props"
      />
    </template>

    <CdsMenuList
      variant="plain"
      size="regular"
    >
      <CdsMenuListItem
        v-for="(option, index) in options"
        :key="index"
        :prepend-icon="option.icon"
        :href="option.href"
        :to="option.to"
        :target="option.target"
        @click="option.onClick"
      >
        {{ option.text }}
      </CdsMenuListItem>
    </CdsMenuList>
  </CdsMenu>
</template>
