// zwraca inicjały z imienia i nazwiska (pierwsza litera z imienia i nazwiska)
// Jan Sebastian Kowalski - JK
// Jan Kowalski - JK
export function getInitials(name: string) {
  const nameArray = name.split(' ');
  const firstNameIn = nameArray[0].charAt(0).toUpperCase();
  const lastNameIn = nameArray[nameArray.length - 1].charAt(0).toUpperCase();

  return firstNameIn + lastNameIn;
}
