import { EnumWorkingItemsCategoryId, type TNavItem } from '~coreTypes/nav.model';

export function useNav() {
  const route = useRoute();

  const localePath = useLocalePath();
  const { $routeNames, $routeNamesWithoutLocalize, $i18n } = useNuxtApp();

  const getActivePageTitle = computed(() =>
    route.meta.subNavTitle ? $i18n.t(`subNav.pages.${route.meta.subNavTitle}`) : null
  );

  // @TODO - zacząć używać gdy pojawią się przedmioty robocze/kreator
  const activeNavData = computed(() => {
    return navItems.value.find(
      (item) => item.children?.find((child) => localePath(child.toWithoutLocale) === route.path)?.pageTitle
    );
  });

  // @TODO - zacząć używać gdy pojawią się przedmioty robocze/kreator
  const navItems = ref<TNavItem[]>([
    {
      text: $i18n.t('nav.workingItems.title'),
      isDisabled: true,
      routeName: $routeNames.bailiff.workingItems, // handle active tab
      children: [
        {
          pageTitle: $i18n.t('nav.workingItems.realEstate.title'),
          text: $i18n.t('nav.workingItems.realEstate.shortTitle'),
          to: { name: $routeNames.bailiff.workingItems, params: { category: EnumWorkingItemsCategoryId.REAL_ESTATE } },
          toWithoutLocale: {
            name: $routeNamesWithoutLocalize.bailiff.workingItems,
            params: { category: EnumWorkingItemsCategoryId.REAL_ESTATE }
          }
        },
        {
          pageTitle: $i18n.t('nav.workingItems.movable.title'),
          text: $i18n.t('nav.workingItems.movable.shortTitle'),
          to: { name: $routeNames.bailiff.workingItems, params: { category: EnumWorkingItemsCategoryId.MOVABLE } },
          toWithoutLocale: {
            name: $routeNamesWithoutLocalize.bailiff.workingItems,
            params: { category: EnumWorkingItemsCategoryId.MOVABLE }
          }
        },
        {
          pageTitle: $i18n.t('nav.workingItems.others.title'),
          text: $i18n.t('nav.workingItems.others.shortTitle'),
          to: { name: $routeNames.bailiff.workingItems, params: { category: EnumWorkingItemsCategoryId.OTHERS } },
          toWithoutLocale: {
            name: $routeNamesWithoutLocalize.bailiff.workingItems,
            params: { category: EnumWorkingItemsCategoryId.OTHERS }
          }
        }
      ]
    },
    {
      text: $i18n.t('nav.auctions.title'),
      isDisabled: false,
      routeName: $routeNames.bailiff.auctions.index, // handle active tab
      children: [
        {
          pageTitle: $i18n.t('nav.auctions.electronic.title'),
          text: $i18n.t('nav.auctions.electronic.shortTitle'),
          to: { name: $routeNames.bailiff.auctions.index },
          toWithoutLocale: { name: $routeNamesWithoutLocalize.bailiff.auctions.index }
        },
        {
          pageTitle: $i18n.t('nav.auctions.traditional.title'),
          text: $i18n.t('nav.auctions.traditional.shortTitle'),
          to: { name: $routeNames.index },
          toWithoutLocale: { name: $routeNamesWithoutLocalize.index },
          isDisabled: true
        }
      ]
    },
    {
      text: $i18n.t('nav.auctionCalendar.title'),
      isDisabled: true,
      routeName: $routeNames.index, // handle active tab
      to: { name: $routeNames.index }
    },
    {
      text: $i18n.t('nav.documents.title'),
      isDisabled: true,
      routeName: $routeNames.index, // handle active tab
      to: { name: $routeNames.index },
      // testowo podpięty children aby pojawiło się rozwijanie
      children: [
        {
          pageTitle: $i18n.t('nav.documents.title'),
          text: $i18n.t('nav.documents.title'),
          to: { name: $routeNames.index },
          toWithoutLocale: { name: $routeNamesWithoutLocalize.index }
        }
      ]
    },
    {
      text: $i18n.t('nav.archives.title'),
      isDisabled: true,
      routeName: $routeNames.index, // handle active tab
      to: { name: $routeNames.index },
      // testowo podpięty children aby pojawiło się rozwijanie
      children: [
        {
          pageTitle: $i18n.t('nav.archives.title'),
          text: $i18n.t('nav.archives.title'),
          to: { name: $routeNames.index },
          toWithoutLocale: { name: $routeNamesWithoutLocalize.index }
        }
      ]
    }
  ]);

  return {
    activeNavData,
    getActivePageTitle,
    navItems
  };
}
