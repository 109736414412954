<script lang="ts" setup>
import { useContextStore } from '~coreStores/context.store';

import { usePLKBreakpoints } from '~coreComposables/breakpoints.composable';

import BailiffHeader from '~coreComponents/navigation/BailiffHeader.vue';
import SubNav from '~coreComponents/navigation/SubNav.vue';

const RegisterFirstKey = defineAsyncComponent(() => import('~coreComponents/auth/Fido/RegisterFirstKey.vue'));

const { $i18n } = useNuxtApp();

useHead({
  titleTemplate: `%s - ${$i18n.t('seo.titlePLK')}`
});

const { isMobile } = usePLKBreakpoints();

const contextStore = useContextStore();
const { getActiveContextIdentify } = storeToRefs(contextStore);
</script>

<template>
  <ClientOnly>
    <div
      class="app"
      :key="getActiveContextIdentify"
    >
      <template v-if="!isMobile">
        <BailiffHeader />

        <SubNav />
      </template>

      <div class="content">
        <div class="container children-element-full-height-flex">
          <slot />
        </div>
      </div>
    </div>

    <component :is="RegisterFirstKey" />
  </ClientOnly>
</template>

<style lang="scss">
@use '~core/cds-assets/scss/helpers' as *;

@include cdsMediaQueryUp('lg') {
  html,
  body,
  #__nuxt {
    height: 100%;
    overflow-y: unset;
  }

  // ustawianie na empty divy generowane przez <slot /> lub <NuxtPage />
  .children-element-full-height-flex > div:not([class]) {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  // ustawianie na empty divy generowane przez <slot /> lub <NuxtPage />
  .children-element-full-height > div:not([class]) {
    height: 100%;
  }
}
</style>

<style lang="scss" scoped>
@use '~core/cds-assets/scss/helpers' as *;

.v-application {
  @include cdsBgColor('surface00');
}

.app {
  display: flex;
  flex-direction: column;

  @include cdsMediaQueryUp('lg') {
    @include cdsBgColor('surface30');
  }

  @include cdsMediaQueryDown('lg') {
    @include cdsBgColor('surface20');
  }
}

@include cdsMediaQueryUp('lg') {
  .app,
  .content {
    height: 100%;
  }
}

.content {
  width: 100%;

  @include cdsMediaQueryUp('lg') {
    padding-bottom: 16px;
    overflow-y: auto;
  }
}

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: var(--app-container-width);
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 0 var(--app-side-paddings);

  @include cdsMediaQueryDown('lg') {
    padding: 0 var(--app-side-paddings-mobile);
  }

  $self: &;
}
</style>
