<script lang="ts" setup>
// Opcje dla zalogowanego komornika w nagłówku

import { useEndSessionCountdown } from '~coreComposables/end-session-countdown.composable';

import HeaderLinks from '~coreComponents/navigation/HeaderLinks.vue';
import HeaderMenu from '~coreComponents/navigation/HeaderMenu.vue';

const { expirationTime, getExpirationTextInfo } = useEndSessionCountdown();
</script>

<template>
  <div class="d-flex align-center gap-xs shrink-0">
    <div
      v-if="expirationTime && getExpirationTextInfo"
      class="end-session"
    >
      <CdsText
        variant="normal-label"
        color="low-emphasis-on-surface"
        >{{ getExpirationTextInfo.label }}</CdsText
      >

      <CdsText
        variant="normal-caption"
        :color="expirationTime < 300 ? 'warning' : 'mid-emphasis-on-surface'"
        >{{ getExpirationTextInfo.value }}</CdsText
      >
    </div>

    <HeaderLinks />

    <HeaderMenu />
  </div>
</template>

<style lang="scss" scoped>
@use '~core/cds-assets/scss/helpers' as *;

.end-session {
  display: flex;
  flex-direction: column;
  text-align: right;
  padding-right: cdsSpace('sm');
  border-right: 1px solid cdsColor('divider-on-surface');
}
</style>
